.participant-list-cont {
  display: flex !important;
}

.participant_denyBtn,
.participant_admitBtn {
  border: 0px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 0.004em !important;
  color: #414042 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 3px 8px !important;
  gap: 8px !important;
  width: 43px !important;
  height: 24px !important;
  background: #fafafa !important;
  border-radius: 2px !important;
}

.participant_admitBtn {
  color: #fff !important;
  background-color: #6f73d2 !important;
  margin-left: 12px;
}

.participant_admitBtn:not([disabled]):hover,
.participant_denyBtn:not([disabled]):hover {
  border: none !important;
}

.close_icon:not([disabled]):hover {
  background-color: transparent !important;
}
