.chat-header__heading {
  color: #182230;
  font-weight: 700;
  font-size: 1rem;
}

.chat-header__dropdown-text {
  color: #667085;
  font-size: 0.875rem;
}

.chat-header__dropdown-btn {
  background: #ffffff !important;
  align-items: center;
}

.dropdown__list-item {
  overflow: auto;
  max-height: 300px;
}

.list__dropdown-item {
  color: #182230;
}

.selector-header__text {
  padding: 1rem 1rem 0.5rem 1rem;
  font-weight: 600;
  font-size: 1rem;
  color: #0c111d;
}

.selector-header__divider {
  color: #2d3440 !important;
  height: 1px;
  border: 0.5px solid #2d3440 !important;
  opacity: 0.1;
}

.selector-item {
  display: flex;
  margin: 0.5rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #0c111d;
  font-weight: 400;
  padding: 0.6rem;
  border-radius: 0.4rem;
}
.selector-item:hover {
  background: #f9fafb;
  cursor: pointer;
}

.selector-item__text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 2rem);
  white-space: nowrap;
}

.particiapnt-list {
  display: flex;
  align-items: center;
  max-width: 235px;
}

.search-cotainer {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 10px;
  z-index: 4 !important;
}

.search-input {
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  background: #fff !important;
  box-shadow: 0 1px 2px #1018280d !important;
  color: #0c111d !important;
  height: 40px !important;
  padding-left: 35px !important;
}

.search-input:focus {
  border-color: #99f6e0 !important;
  box-shadow: 0 0 0 4px #15b79e3d !important;
  background: #fff !important;
}

.search-input:hover:not(:is(:focus, :disabled)) {
  border-color: #98a2b3 !important;
}

.chat-footer {
  display: flex;
  align-items: center;
  max-height: 6rem;
  min-height: 3rem;
  gap: 0.75rem;
  position: relative;
}

.send_msg_btn {
  background: #15b79e !important;
  border-radius: 8px !important;
  height: 44px !important;
  width: 54px;
  color: #ffffff;
}

.send_msg_btn > svg {
  height: 20px;
  width: 20px;
}

.chat-selector {
  padding: 0.7rem;
}

